import React, { useEffect, useState } from "react";
import Titlebanner from "../../../globel_cmponents/title_banner";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import axios from "axios";
import requests from "../../../utils/Requests";
const BookingWithTransfer = () => {
  const [rowData, setrowData] = useState([]);

  useEffect(() => {
    getBookingList();
  }, []);
  const getBookingList = async () => {
    await axios
      .post(requests.getTransferBookingList, { bookingId: 1 })
      .then((response) => {
        console.log(response.data);
        const data = response.data;

        // Map and filter the data
        const mappedData = data.map((item) => {
          // Check the sharedOrPrivate value and set transfer type
          if (item.sharedOrPrivate === 2) {
            item.sharedOrPrivate = "Shared Transfer";
          } else if (item.sharedOrPrivate === 3) {
            item.sharedOrPrivate = "Private Transfer";
          }
          item.bookDate = item.bookDate?.slice(0, 10);

          return item;
        });

        // Set the rowData state with the mapped and filtered data
        setrowData(mappedData);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const columnDefs = [
    { headerName: "Booking RefNo", field: "bookingId" },
    { headerName: "Invoice ", field: "xeroInvoiceNumber" },

    { headerName: "Pax Name", field: "bookCustomerName" },
    { headerName: "Number", field: "bookMobileNumber" },
    { headerName: "Pickup Location", field: "pickupLocation" },
    { headerName: "Pickup Time", field: "pickupTime" },
    { headerName: "Sales Amount", field: "bookTotal" }, //pickupLocation
    { headerName: "Sales Date", field: "bookDate" },
  ];
  const defaultColDef = {
    sortable: true,
    editable: false,
    //flex: 1,
    filter: true,
    floatingFilter: true,
    resizable: true,
  };

  return (
    <div>
      <Titlebanner title="Booking with transfer List " />
      <div className="App">
        <div className="ag-theme-alpine" style={{ height: "500px" }}>
          <AgGridReact
            id="Inventory"
            columnDefs={columnDefs}
            rowData={rowData}
            defaultColDef={defaultColDef}
            // onGridReady={onGridReady}
            pagination={true}
            // paginationPageSize={5}
            // paginationAutoPageSize={true}
            alwaysShowHorizontalScroll={true}
            alwaysShowVerticalScroll={true}
          ></AgGridReact>
        </div>
      </div>
    </div>
  );
};

export default BookingWithTransfer;
