import Titlebanner from "../../../globel_cmponents/title_banner";
import { Row, Col } from "react-bootstrap";
const Ticketbookingform = () => {
  return (
    <>
      <Titlebanner title="Booking Form" />
    </>
  );
};

export default Ticketbookingform;
