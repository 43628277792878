import React, { useEffect, useState } from "react";
import Titlebanner from "../../../globel_cmponents/title_banner";
import axios from "axios";
import requests from "../../../utils/Requests";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { Link } from "react-router-dom";
import { reduxForm, Field } from "redux-form";
import {
  Card,
  CardBody,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import RenderField from "../../formcomponent/formfields/RenderField";
import Swal from "sweetalert2";
const AttractionTransferList = (props) => {
  const { handleSubmit, pristine, reset, submitting } = props;
  useEffect(() => {
    getTransferList();
    getallparkgroup();
  }, []);
  const [rowData, setrowData] = useState([]);
  const [parkGroup, setparkGroup] = useState([{ value: "", name: "" }]);
  const [attractionList, setattractionList] = useState([
    { name: "", value: "" },
  ]);
  const getallparkgroup = async () => {
    await axios
      .post(requests.getallparkgroup, { attractionsId: 1 })
      .then((res) => {
        // console.log(res.data)

        const values = [...parkGroup];
        for (let i = 0; i < res.data.length; i++) {
          values.push({
            name: res.data[i].grpName,
            value: res.data[i].attractionGroupId,
          });
          // console.log(values);
        }
        setparkGroup(values);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getattractionbygroup = async (groupId) => {
    await axios
      .post(requests.getattractionallbygroup, { attGroup: groupId })
      .then((res) => {
        const values = [...attractionList];
        values.length = 0;
        values.push({
          name: "Select Attraction",
          value: "",
        });
        for (let i = 0; i < res.data.length; i++) {
          values.push({
            name: res.data[i].attName,
            value: res.data[i].attractionsId,
          });
        }
        setattractionList(values);
      })
      .catch((err) => {});
  };
  const getTransferList = async (id) => {
    await axios
      .post(requests.getAttractionTransferList, { attractionId: id })
      .then((res) => {
        console.log(res.data);

        setrowData(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const columnDefs = [
    { headerName: "Name", field: "pickupLocation" },
    { headerName: "Starting Time", field: "startingTime" },
    { headerName: "Cost", field: "costPerPerson" },
    {
      headerName: "Action",
      filter: false,
      field: "attractionTransferId",
      cellRendererFramework: (params) => (
        <div>
          <Button color="danger" onClick={() => actionButton(params)}>
            Delete
          </Button>
        </div>
      ),
    },
  ];

  const actionButton = async (params) => {
    await axios
      .post(requests.deleteAttractionTransfer, {
        attractionTransferId: params.data.attractionTransferId,
      })
      .then((res) => {
        getTransferList(params.data.attractionTransferId);
        console.log(res.data);
        Swal.fire("Deleted", "Attraction Transfer Deleted", "success");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const defaultColDef = {
    sortable: true,
    editable: true,
    flex: 1,
    filter: true,
    floatingFilter: true,
  };
  return (
    <div>
      <Titlebanner title="Attraction Transfer List" />
      <div className="float-right">
        <Link
          to="/Trasnport/AttractionTransport/transport-create-attraction-transport"
          class="btn btn-primary"
          role="button"
        >
          Create New
        </Link>
      </div>
      <br />
      <br />

      <Row>
        <Col>
          <FormGroup>
            <Label for="exampleSelect">Select Attraction Group</Label>
            <Input
              type="select"
              name="select"
              id="exampleSelect"
              onChange={(e) => getattractionbygroup(e.target.value)}
            >
              {parkGroup.map((attration, index) => (
                <option value={attration.value}> {attration.name}</option>
              ))}
            </Input>
          </FormGroup>
        </Col>
        <Col>
          <Field
            name="attractionsId"
            type="select"
            customfeild={attractionList}
            component={RenderField.renderOptionField}
            id="ttAttractionId"
            onChange={(e) => getTransferList(e.target.value)}
            label="Select Attraction "
          />
        </Col>
      </Row>
      <div className="App">
        <div className="ag-theme-alpine" style={{ height: "500px" }}>
          <AgGridReact
            columnDefs={columnDefs}
            rowData={rowData}
            defaultColDef={defaultColDef}
            //onGridReady={onGridReady}
            pagination={true}
            // paginationPageSize={5}
            paginationAutoPageSize={true}
          ></AgGridReact>
        </div>
      </div>
    </div>
  );
};

export default reduxForm({
  form: "AttractionTransferList",
})(AttractionTransferList);
