import { path } from "react-dom-factories";

const ItteamMenu = [
  {
    name: "Dashboard",
    fixMenu: true,
    path: "/dashboard/dashboard/index",
    SpanIcon: "nav-icon fas fa-th",
    SideMenu: [],
  },

  {
    name: "Website Settings",
    // path: "/admin",
    SpanIcon: "fa fa-cog",
    fixMenu: false,
    admin: true,
    SideMenu: [
      {
        name: "Banner Images",
        path: "/websiteSettings/bannerImages/admin-banner-images",
      },
      {
        name: "Banner Image(BtoC)  ",
        path: "/websiteSettings/bannerImages/admin-banner-imagesbtoc",
      },
      {
        name: "Language  Settings",
        path: "/websiteSettings/languageSettings/admin-banner-images",
      },
    ],
  },
  {
    name: "Admin",
    // path: "/admin",
    SpanIcon: "fa fa-user-secret",
    fixMenu: false,
    admin: true,
    SideMenu: [
      {
        name: "Roles",
        path: "/admin/roles/roles",
        adminRoles: true,
      },
      {
        name: "Role Program Link",
        path: "/admin/roleProgramLink/role-programe-link",
        adminRolesProgramLink: true,
      },
      {
        name: "Customer Type",
        path: "/admin/CustomerType/admin-customer-type",
        adminCustomerType: true,
      },
      {
        name: "Ticket Type",
        path: "/admin/TicketType/admin-ticket-type-creation/null",
        adminTicketTypeNew: true,
      },

      {
        name: "Sub-User Creation",
        path: "/admin/SubUserCreation/admin-passenger-type",
        adminPassengerType: true,
      },
      {
        name: "Agency Creation",
        path: "/admin/AgencyCreation/admin-agency-creation",
        adminAgencyCreation: true,
      },
      {
        name: "Generate PDF",
        path: "/admin/GeneratePDF/admin-pdf-generate",
      },
    ],
  },
  {
    name: "Payment",
    // path: "/payment",
    payment: true,
    SpanIcon: "fa fa-credit-card",
    fixMenu: false,
    SideMenu: [
      {
        name: "Payment Creation",
        path: "/Payment/PaymentCreation/payment-payment-creation",
        paymentCreation: true,
      },
      {
        name: "Markup Discount",
        path: "/Payment/markupDiscount/payment-markup-discount",
        paymentMarkupDiscount: true,
      },
    ],
  },
  {
    name: "API Settings",
    // path: "/payment",
    payment: true,
    SpanIcon: "fa fa-credit-card",
    fixMenu: false,
    SideMenu: [
      {
        name: "API Master",
        path: "/APISettings/APIMaster/attraction-connect-with_api",
        attractionList: true,
      },
      {
        name: "Price Settings",
        path: "/APISettings/PriceSettings/apimaster-price-settings",
        paymentOnlinePayment: true,
      },
      {
        name: "MarkUp Discount",
        path: "/APISettings/APISettings/apimaster-markup-discount",
        paymentMarkupDiscount: true,
      },
    ],
  },
  {
    name: "Agent API Settings",
    // path: "/payment",
    // payment: true,
    SpanIcon: "fa fa-credit-card",
    fixMenu: false,
    SideMenu: [
      {
        name: "Create Access Permistion",
        path: "/AgentAPISettings/CreateAccesspermission/api-access-permistion",
        attractionList: true,
      },
      {
        name: "Upload Test(Dummy) Ticket",
        path: "/AgentAPISettings/UploadTestDummyTicket/api-upload-test-tickets",
        paymentOnlinePayment: true,
      },
    ],
  },
  {
    name: "Attraction",
    fixMenu: false,
    SpanIcon: "fa fa-globe",
    SideMenu: [
      {
        name: "Attraction Creation",
        path: `/Attraction/AttractionCreation/attraction-attraction-creation/null`,
        attractionCreation: true,
      },

      {
        name: "Attraction List",
        path: "/Attraction/AttractionList/attraction-attraction-list",
        attractionList: true,
      },
      {
        name: "Set Holidays",
        path: "/Attraction/SetHolidays/attraction-set-hollydays",
      },
      {
        name: "API Master",
        path: "/APISettings/APIMaster/attraction-connect-with_api",
        attractionList: true,
      },
      {
        name: "Ticket Creation",
        path: "/Attraction/TicketCreation/attraction-ticket-creation",
        attractionTicketCreation: true,
      },
      {
        name: "Ticket Creation (Allow Dublicate)",
        path: "/Attraction/TicketCreation/attraction-ticket-creation-withdublicate",
        // attractionTicketCreation: true,
      },
      {
        name: "Ticket Generation",
        path: "/Attraction/TicketGeneration/attraction-ticket-generate-temp",
      },
      {
        name: "Ticket List",
        path: "/Attraction/TicketList/attraction-ticket-list",
        attractionTicketList: true,
      },
      {
        name: "Combo Pack Creation",
        path: "/Attraction/ComboPackCreation/attraction-combo-pack-creation/null",
        //attractionParkGrouping:true
      },
      {
        name: "Combo Pack list",
        path: "/Attraction/ComboPacklist/attraction-combo-pack-list",
        //attractionParkGrouping:true
      },
      {
        name: "Park Grouping",
        path: "/Attraction/ParkGrouping/attraction-park-grouping",
        attractionParkGrouping: true,
      },
      {
        name: "Banner Add-On",
        path: "/Attraction/BannerAddOn/attraction-banner-add-on",
      },
      {
        name: "Form Add-On",
        path: "/Attraction/FormAddOn/attraction-form-add-on",
      },
      {
        name: "Add-On Barcode",
        path: "/Attraction/AddOnBarcode/attraction-form-add-on-BarcodeUpload",
      },
    ],
  },
  {
    name: "Tour Pack",
    fixMenu: false,
    tourPackage: true,
    SpanIcon: "fa fa-globe",
    SideMenu: [
      {
        name: "Tour Pack Group",
        path: "/TourPack/TourPackGroup/attraction-tour-pack-group",
      },
      {
        name: "Tour Pack Creation",
        path: "/TourPack/TourPackCreation/attraction-tour-pack-creation/null",
        tourPackageCreation: true,
      },
      {
        name: "Tour Pack List",
        path: "/TourPack/TourPackList/attraction-tour-pack-list",
        tourPackageList: true,
      },
    ],
  },
  {
    name: "Booking",
    fixMenu: false,

    SpanIcon: "fa fa-book",
    SideMenu: [
      {
        name: "Booking",
        path: "/Booking/Booking/ticket-booking",
        booking: true,
      },
      {
        name: "Cancellation",
        path: "/booking/Booking/Cancellation/ticket-cansolation",
        bookingCancellation: true,
      },
      {
        name: "Return Tickets",
        path: "/Booking/ReturnTickets/ticket-return",
        bookingReturnTickets: true,
      },
    ],
  },

  {
    name: "Hotel",
    // path: "/payment",
    // payment: true,
    SpanIcon: "fa fa-building",
    fixMenu: false,
    SideMenu: [
      {
        name: "Hotel Aminities",
        path: "/Hotel/HotelAminities/hotel-list-amities",
      },
      {
        name: "List Hotel",
        path: "/Hotel/ListHotel/hotel-list-hotel",
      },
      {
        name: "Create Hotel",
        path: "/Hotel/CreateHotel/hotel-create-hotel/null",
      },
      {
        name: "Hotel Enquiry List",
        path: "/Hotel/HotelEnquiryList/hotel-create-hotel",
      },
    ],
  },

  {
    name: "Trasnport",
    // path: "/payment",
    // payment: true,
    SpanIcon: "fa fa-credit-card",
    fixMenu: false,
    SideMenu: [
      {
        name: "Attraction Transport",
        path: "/Trasnport/AttractionTransport/transport-attraction-transport",
      },
      {
        name: "List Transport",
        path: "/Trasnport/ListTransport/transport-list-transport",
      },
      {
        name: "Transport Booked List",
        path: "/Trasnport/TransportBookedList/transport-transport-booking-list",
      },
      // {
      //   name: "Create Transport",
      //   path: "/Trasnport/ListTransport/transport-create-transport",
      // },
      // {
      //   name: "Link To Attraction",
      //   path: "/transport/link-transport-list",
      // },
    ],
  },
  {
    name: "Review And Faq",
    // path: "/admin",
    SpanIcon: "fa fa-cog",
    fixMenu: false,
    admin: true,
    SideMenu: [
      {
        name: "Review",
        path: "/ReviewAndFaq/Review/randf-reviewlist",
      },
      {
        name: "FAQ",
        path: "/ReviewAndFaq/FAQ/randf-faqslist",
      },
    ],
  },
  {
    name: "Reports",
    fixMenu: false,
    reports: true,
    SpanIcon: "fa fa-list-alt",
    SideMenu: [
      {
        name: "Daily Sales ",
        path: "/Reports/DailySales/report-daily-sales-report",
        reportsDailySalesReport: true,
      },
      {
        name: "B2C Sales",
        path: "/Reports/B2CSales/report-b2c-sales-report",
        reportsDailySalesReport: true,
      },
      {
        name: "Monthly Sales ",
        path: "/Reports/MonthlySales/report-monthly-sales-report",
      },
      {
        name: "API Ticket ",
        path: "/Reports/APITicket/report-api-ticket-booking-report",
      },
      {
        name: "Booking List",
        path: "/Reports/BookingList/report-all-booking-list",
      },
      {
        name: "Attraction With Transfer ",
        path: "/Reports/AttractionWithTransfer/report-attractiontkt-withtransfer",
      },
      {
        name: "Return Tickets",
        path: "/Reports/Reports/report-return-ticket-list",
      },

      {
        name: "Addon Stock",
        path: "/Reports/AddonStock/report-addon-stock-report",
      },
      {
        name: "Agent Sales Report",
        path: "/Reports/AgentSalesReport/report-agent-sales-report",
      },
      // {
      //   name: "Cancellation Report",
      //   path: "/report/cancellation-report",
      //   reportsCancellationReport: true,
      // },
      {
        name: "Stock Summery ",
        path: "/Reports/StockSummery/report-stack-summery-report",
        reportsStackSummaryReport: true,
      },
      {
        name: "Inventory ",
        path: "/Reports/Inventory/report-inventory-detail-report",
        reportsInventoryDetailReport: true,
      },
      {
        name: "Ticket Availability ",
        path: "/Reports/TicketAvailability/report-ticket-availability-list",
        reportsInventoryDetailReport: true,
      },
      // {
      //   name: "Statment Report",
      //   path: "/report/statment-report",
      //   reportsStatementReport: true,
      // },
    ],
  },

  {
    name: "Enquiry List",
    fixMenu: false,
    // reports: true,
    SpanIcon: "fa fa-list-alt",
    SideMenu: [
      {
        name: "Tour Enquiry",
        path: "/EnquiryList/TourEnquiry/attraction-tour-pack-enquiry",
      },
      {
        name: "Visa Enquiry",
        path: "/EnquiryList/VisaEnquiry/enquiry-visa-enquiry",
      },
      {
        name: "Common Enquiry",
        path: "/EnquiryList/CommonEnquiry/enquiry-common-enquiry",
      },
    ],
  },
  {
    name: "Tour Visionary Markups",
    // path: "/payment",
    payment: true,
    SpanIcon: "fa fa-credit-card",
    fixMenu: false,
    SideMenu: [
      // {
      //   name: "Markup List",
      //   path: "/payment/tourvisionary-listmarkups",
      //   paymentCreation: true,
      // },

      // {
      //   name: "Create Markup ",
      //   path: "/payment/tourvisionary-createmarkup/null",
      //   paymentMarkupDiscount: true,
      // },
      {
        name: "Create API Markup ",
        path: "/TourVisionaryMarkups/CreateAPIMarkup/payment-tourvisionary-createapimarkup",
        paymentMarkupDiscount: true,
      },
    ],
  },
];
export default ItteamMenu;
