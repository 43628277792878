import React, { useEffect, useState } from "react";
import {
  BrowserRouter,
  Redirect,
  Switch,
  Route,
  withRouter,
} from "react-router-dom";
import RouteWithSubRoute from "../../../utils/route_with_sub_route";
import routes from "../route/Routes";
import NotFound from "../../not_found";
import encryptStorage from "../../../utils/EncryptStorage";
import SideMenu from "../route/sidemenu";
import accountsMenu from "../route/accountsMenu";
import BackOfficeAdmin from "../route/backOfficeAdmin";
import ItteamMenu from "../route/itteamMenu";

const Container = () => {
  const [auth, setAuth] = useState(false);
  const [tempAuth, setTempAuth] = useState(true);
  const [mainMenu, setMainMenu] = useState(SideMenu); // Initialize with SideMenu

  useEffect(() => {
    const loginDetails = encryptStorage.getItem("DFuck");
    let userType = JSON.parse(loginDetails);

    const currentPathname = window.location.pathname;
    const secondSegment = currentPathname.split("/")[2];
    let isAuthorized = false;

    const tempMenu = SideMenu;
    // Check authorization
    for (let i = 0; i < tempMenu.length; i++) {
      if (
        tempMenu[i].fixMenu &&
        secondSegment === tempMenu[i].path.split("/")[2]
      ) {
        isAuthorized = true;
        break;
      }

      for (let j = 0; j < tempMenu[i].SideMenu.length; j++) {
        if (secondSegment === tempMenu[i].SideMenu[j].path.split("/")[2]) {
          isAuthorized = true;
          break;
        }
      }
    }

    setAuth(isAuthorized);
    setTempAuth(false); // Set loading state to false
  }, []);

  // Get the first menu item path
  const getFirstMenuPath = () => {
    if (mainMenu.length > 0) {
      // Check if the top-level menu item has a path, if not, look for the first SideMenu path
      if (mainMenu[0].path) {
        return mainMenu[0].path;
      } else if (mainMenu[0].SideMenu && mainMenu[0].SideMenu.length > 0) {
        return mainMenu[0].SideMenu[0].path; // Return the first SideMenu path
      }
    }
    return "/dashboard/dashboard/index"; // Fallback path
  };

  // Handle Not Found redirect
  if (!tempAuth && !auth) {
    const currentPathname = window.location.pathname;
    const secondSegment = currentPathname.split("/")[2];

    const allowedPaths = mainMenu
      .flatMap((menu) => {
        const mainPath = menu.path ? menu.path.split("/")[2] : null;
        const subPaths = menu.SideMenu
          ? menu.SideMenu.map((subMenu) =>
              subMenu.path ? subMenu.path.split("/")[2] : null
            )
          : [];

        return [mainPath, ...subPaths];
      })
      .filter(Boolean); // Filter out null values

    if (!allowedPaths.includes(secondSegment)) {
      return <Redirect to="/not-found" />; // Redirect to Not Found page
    }
  }

  return (
    <>
      <div className="content-wrapper">
        <section className="content">
          <div className="container-fluid">
            {tempAuth ? (
              <Switch>
                <Route exact path="/">
                  <Redirect to={getFirstMenuPath()} />
                </Route>
              </Switch>
            ) : (
              <>
                {auth ? (
                  <Switch>
                    <Route exact path="/">
                      <Redirect to={getFirstMenuPath()} />
                    </Route>
                    {routes.map((route, i) => (
                      <RouteWithSubRoute key={i} {...route} />
                    ))}
                    <Route component={NotFound} />
                  </Switch>
                ) : (
                  <Switch>
                    <Route component={NotFound} />
                  </Switch>
                )}
              </>
            )}
          </div>
        </section>
      </div>
    </>
  );
};

export default withRouter(Container);
