const baseURL = "https://parmartours.com:8443/parmartour/v1.0/";
// "https://parmartours.com:8443parmartour-test/v1.0/"; //  /v1.54254785215/"   local: http://192.168.43.214:8443/  server http://parmartours.com:8080/parmartour/
const imagebaseUrl = "https://parmartours.com:8443"; //Server for image http://parmartours.com:8080/ local http://192.168.43.214:8443/
const requests = {
  image: "https://parmartours.com:8443/parmartour/", // http://parmartours.com:8080/parmartour/v1.0/fileupload   //testing server 103.235.106.127  http://103.235.106.127:8080/parmartour/
  generateXeroInvoice: baseURL + "generateXeroInvoice",
  getDashboardSalesList: baseURL + "getDashboardSalesList",
  getSuperAdminList: baseURL + "getSuperAdminList",
  removeUserData: baseURL + "removeUserData",
  getBookingList: baseURL + "getBookingList",
  getGenerateTicketPdf: baseURL + "getGenerateTicketPdf",
  setApiMarkupDiscount: baseURL + "setApiMarkupDiscount",
  removeApiMarkupDiscount: baseURL + "removeApiMarkupDiscount",
  updateApiMarkupDiscount: baseURL + "updateApiMarkupDiscount",
  getApiMarkupDiscountList: baseURL + "getApiMarkupDiscountList",
  getTicketsExpiringShortly: baseURL + "getTicketsExpiringShortly",
  setApiRate: baseURL + "setApiRate",
  getApiRateList: baseURL + "getApiRateList",
  updateApiRate: baseURL + "updateApiRate",
  removeApiRate: baseURL + "removeApiRate",
  setenquiry: baseURL + "setenquiry",
  setVisa: baseURL + "setVisa",
  getVisaList: baseURL + "getVisaList",
  cancelBurjTicket: baseURL + "cancelBurjTicket",
  setComboOffer: baseURL + "setComboOffer",
  updateComboOffer: baseURL + "updateComboOffer",
  getComboOfferList: baseURL + "getComboOfferList",
  getComboOffer: baseURL + "getComboOffer",
  getTourCategoryList: baseURL + "getTourCategoryList",
  setTourCategoryDetails: baseURL + "setTourCategoryDetails",
  setAddonBarcode: baseURL + "setAddonBarcode",
  getBarcodeFromExcel: baseURL + "getBarcodeFromExcel",
  getUserDataList: baseURL + "getUserDataList",
  // setaddonformlist: baseURL + "setaddonformlist",
  removeWebsiteBannerImagesList: baseURL + "removeWebsiteBannerImagesList",
  getWebsiteBannerImagesList: baseURL + "getWebsiteBannerImagesList",
  uploadWebsiteBannerImages: baseURL + "uploadWebsiteBannerImages",
  setUserData: baseURL + "setUserData",
  apiKey: "uZFEucIHAbqvgT7p87qC4Ms4tjqG34su",
  updateTicketType: baseURL + "updateTicketType",
  getDashboardSalesReport: baseURL + "getDashboardSalesReport",
  getUserData: baseURL + "getUserData",
  getVisaEnquiryList: baseURL + "getVisaEnquiryList",
  getSalesReportForBurjKhalifa: baseURL + "getSalesReportForBurjKhalifa",
  getGeneralEnquiryList: baseURL + "getGeneralEnquiryList",
  getTourEnquiryList: baseURL + "getTourEnquiryList",
  getenquirylist: baseURL + "getenquirylist",
  getenquiry: baseURL + "getenquiry",
  getTicketTypeForAttraction: baseURL + "getTicketTypeForAttraction",
  cancelBooking: baseURL + "cancelBooking",
  getAttractionListForUpdate: baseURL + "getAttractionListForUpdate",
  updateAgencyDetails: baseURL + "updateAgencyDetails",
  updateTicketDetails: baseURL + "updateTicketDetails",
  getTopupList: baseURL + "getTopupList",
  setAgencyCreditLimit: baseURL + "setAgencyCreditLimit",
  getSalesReport: baseURL + "getSalesReport",
  getSalesReportForSuperAdmin: baseURL + "getSalesReportForSuperAdmin",
  getTicketListForAttraction: baseURL + "getTicketListForAttraction",
  getTicketListForAttractionReport:
    baseURL + "getTicketListForAttractionReport",
  getAttractionListForTicketReport:
    baseURL + "getAttractionListForTicketReport",
  getticketinfofromferraripdf: baseURL + "getticketinfofromferraripdf",
  getTicketInfoFromExpoPdf: baseURL + "getTicketInfoFromExpoPdf",
  getticketinfofrompdf: baseURL + "getticketinfofrompdf",
  getticketinfofromexcel: baseURL + "getticketinfofromexcel",
  getbookingListAll: baseURL + "getbookingListAll",
  updateattraction: baseURL + "updateattraction",
  getAttractionAllForList: baseURL + "getattractionallForList",
  getattractionall: baseURL + "getattractionall",
  getAttractionDetails: baseURL + "getattractiondetails",
  gettourpackagelist: baseURL + "gettourpackageall",
  getticketall: baseURL + "getTicketListAll",
  getagencylist: baseURL + "getagencylist",
  getagencydetail: baseURL + "getagencydetails",
  getallparkgroup: baseURL + "getattractiongrouplist",
  getTourPackageSingle: baseURL + "gettourpackageDetails",
  getuserlist: baseURL + "setuserroleslist",
  getuserrolesdetail: baseURL + "getuserrolesdetail",
  getattractionallbygroup: baseURL + "getattractionallbygroup",
  gettickettypedetails: baseURL + "gettickettypedetails",
  gettickettypelist: baseURL + "gettickettypelist",
  setcustomercypedetails: baseURL + "setcustomercypedetails",
  getCustomerTypeAgency: baseURL + "getCustomerTypeAgency",
  setCustomerTypeAgency: baseURL + "setCustomerTypeAgency",
  getmarkupdiscountlist: baseURL + "getmarkupdiscountlist",
  getcustomertypelist: baseURL + "getcustomertypelist",
  getTicketTypeListByAttraction: baseURL + "gettickettypelistbyattraction",
  getagencyuserlistforagency: baseURL + "getagencyuserlistforagency",
  setmarkupdiscountdetail: baseURL + "setmarkupdiscountdetail",
  setaddonbannerdetails: baseURL + "setaddonbannerdetails",
  getaddonbannerlist: baseURL + "getaddonbannerlist",
  getaddonformdetails: baseURL + "setaddonformdetails",
  getTicketGroupListForAttraction: baseURL + "getTicketGroupListForAttraction",
  getTicketBatchList: baseURL + "getTicketBatchList",
  setaddonformlist: baseURL + "setaddonformlist",
  postattraction: baseURL + "setattraction",
  posttktcreation: baseURL + "setticket",
  posttourpackage: baseURL + "settourpackage",
  postagencycreation: baseURL + "setagencydetails",
  postparkgroupping: baseURL + "setattractiongroup",
  postagencyuser: baseURL + "setagencyuser",
  settickettype: baseURL + "settickettype",
  postuserrolecreation: baseURL + "setuserrolesdetail",
  updateTicketExpiry: baseURL + "updateTicketExpiry",
  imgpath: imagebaseUrl + "filestorage/parmartour/images/",
  getExpiredTicketList: baseURL + "getExpiredTicketList",
  getExpiredTicketDetails: baseURL + "getExpiredTicketDetails",
  removeExpiredTickets: baseURL + "removeExpiredTickets",
  getMonthlySalesReport: baseURL + "getMonthlySalesReport",
  setAttractionHolidayListDto: baseURL + "setAttractionHolidayListDto",
  getAttractionHolidayListDto: baseURL + "getAttractionHolidayListDto",
  removeAttractionHolidayListDto: baseURL + "removeAttractionHolidayListDto",
  getStatementReport: baseURL + "getStatementReport",
  getAddonBarcodeForAttraction: baseURL + "getAddonBarcodeForAttraction",
  deleteTicketUnusedTickets: baseURL + "deleteTicketUnusedTickets",
  setTestTicket: baseURL + "setTestTicket",
  deleteMarkupDiscount: baseURL + "deleteMarkupDiscount",
  setAttractionTransfer: baseURL + "setAttractionTransfer",
  getAttractionTransferList: baseURL + "getAttractionTransferList",
  deleteAttractionTransfer: baseURL + "deleteAttractionTransfer",
  createAmenities: baseURL + "createAmenities",
  getAmenitiesList: baseURL + "getAmenitiesList",
  deleteAmenities: baseURL + "deleteAmenities",
  createHotel: baseURL + "createHotel",
  getHotelList: baseURL + "getHotelList",
  getLanguagesListFromGoogle: baseURL + "getLanguagesListFromGoogle",
  translateText: baseURL + "translateText",
  createLanguages: baseURL + "createLanguages",
  getLanguagesList: baseURL + "getLanguagesList",
  editLanguages: baseURL + "editLanguages",
  deleteLanguages: baseURL + "deleteLanguages",
  addCustomerReview: baseURL + "addCustomerReview",
  getCustomerReviewList: baseURL + "getCustomerReviewList",
  deleteCustomerReview: baseURL + "deleteCustomerReview",
  addAttractionFaqs: baseURL + "addAttractionFaqs",
  getAttractionFaqsList: baseURL + "getAttractionFaqsList",
  deleteAttractionFaqs: baseURL + "deleteAttractionFaqs",
  generateTicket: baseURL + "generateTicket",
  createTransfer: baseURL + "createTransfer",
  getTransferList: baseURL + "getTransferList",
  getTransferBookingList: baseURL + "getTransferBookingList",
  getPrivateTransferList: baseURL + "getPrivateTransferList",
  getHotelBookingList: baseURL + "getHotelBookingList",
  getTicketListAvailability: baseURL + "getTicketListAvailability",
  updateTicketTypeShowInReport: baseURL + "updateTicketTypeShowInReport",
  getSalesReportForB2b: baseURL + "getSalesReportForB2b",
  getAgentWiseReport: baseURL + "getAgentWiseReport",
  getB2cSalesReport: baseURL + "getB2cSalesReport",
  deleteAddonBannerDetails: baseURL + "deleteAddonBannerDetails",
};
export default requests;
