import React, { useState, useEffect } from "react";
import Titlebanner from "../../../globel_cmponents/title_banner";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { Field, Form, reduxForm } from "redux-form";
import RenderField from "../../formcomponent/formfields/RenderField";
import { Button, Row, Col, Alert, Spinner } from "reactstrap";
import axios from "axios";
import requests from "../../../utils/Requests";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
const Agentsalesreport = (props) => {
  const [rowData, setrowData] = useState([]);
  const { handleSubmit, pristine, reset, submitting } = props;
  useEffect(() => {
    getagency();
  }, []);

  const [agencyList, setagencyList] = useState([]);
  const [isLoading, setisLoading] = useState(false);

  const getagency = () => {
    axios
      .post(requests.getagencylist, {
        platformId: 1,
        attractionsId: 1,
        secretKey: requests.apiKey,
      })
      .then((res) => {
        console.log("res", res.data);

        let values = [...agencyList];
        values.length = 0;
        values.push({
          name: "Select Agent",
          value: 0,
        });
        for (let i = 0; i < res.data.length; i++) {
          values.push({
            name: res.data[i].agencyName,
            value: res.data[i].agencyId,
          });
        }
        setagencyList(values);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAgentSalesReport = async (values) => {
    setisLoading(true);
    console.log(values);
    try {
      const response = await axios.post(requests.getAgentWiseReport, values);
      console.log(response.data);

      const organizedData = response.data.map((item) => ({
        bookingId: item.bookingId,
        portel_userName: item.agencyName,
        agentRefNumber: item.agentRefNumber,
        bookingRefNumber: item.bookNumber,
        attractionName: item.attractionName,
        paxName: item.bookCustomerName,
        contactNumber: item.bookMobileNumber,
        no_Adult: item.bookNofAdult,
        no_Child: item.bookNofChild,
        AdultPrice: item.bookAdultPrice,
        ChildPrice: item.bookChildPrice,
        Total: item.bookTotal,
        bookingDate: new Date(item.bookDate).toLocaleDateString(),
        bookTravellDate: new Date(item.bookTravellDate).toLocaleDateString(),
        InvoiceNumber: item.xeroInvoiceNumber,
        bookPaymentMode: paymode(item.bookPaymentMode),
        pdfFileName: item.bookingTickPdfPath,
      }));
      setisLoading(false);
      console.log(organizedData);
      setrowData(organizedData);

      // Do something with organizedData, for example, set it in the component state or return it.
    } catch (error) {
      console.log(error);
    }
  };

  const paymode = (bookPaymentMode) => {
    if (bookPaymentMode == 1) {
      return "Online";
    } else {
      return "Credit";
    }
  };

  const columnDefs = [
    { headerName: "Booking Id", field: "bookingId" },
    { headerName: "Portel User Name", field: "portel_userName" },
    { headerName: "Agent Ref Number ", field: "agentRefNumber" },
    { headerName: "Book Ref Number", field: "bookingRefNumber" },
    { headerName: "Attraction Name", field: "attractionName" },
    { headerName: "Pax Name", field: "paxName" },
    { headerName: "Contact Number", field: "contactNumber" },

    { headerName: "no.Adult", field: "no_Adult" },
    { headerName: "no.Child", field: "no_Child" },
    { headerName: "Adult Price", field: "AdultPrice" },
    { headerName: "Child Price", field: "ChildPrice" },

    { headerName: "Total", field: "Total" },
    { headerName: "Book Date", field: "bookingDate" },
    { headerName: "Travel Date", field: "bookTravellDate" },
    { headerName: "Invoice", field: "InvoiceNumber" },
    { headerName: "PaymentMode", field: "bookPaymentMode" },
    { headerName: "Pdf File Name", field: "pdfFileName" },
  ];
  const defaultColDef = {
    sortable: true,
    editable: true,
    flex: 1,
    filter: true,
    floatingFilter: true,
    resizable: true,
  };
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  return (
    <>
      <Titlebanner title="Agent Sales Report" />
      <Form onSubmit={handleSubmit(getAgentSalesReport.bind(this))}>
        <Row>
          <Col sm={3}>
            <Field
              name="bookB2bId"
              type="select"
              customfeild={agencyList}
              label="Select Agent"
              component={RenderField.renderOptionField}
            />
          </Col>

          <Col sm={3}>
            <Field
              name="filterStartDate"
              type="date"
              label="Start Date"
              component={RenderField.RenderTextField}
            />
          </Col>
          <Col sm={3}>
            <Field
              name="filterEndDate"
              type="date"
              label="End Date"
              component={RenderField.RenderTextField}
            />
          </Col>
        </Row>
        <div className="float-center">
          <Button color="primary" disabled={submitting}>
            Submit
          </Button>{" "}
          &nbsp; &nbsp;&nbsp;
        </div>
      </Form>
      <br />

      {isLoading == true ? (
        <>
          {" "}
          <Alert color="primary">
            Your Data Is Loading Please Wait <Spinner color="light" />
          </Alert>
        </>
      ) : null}
      {rowData.length > 0 && (
        <Button
          color="primary"
          onClick={(e) => exportToCSV(rowData, "Agent_Sales_Report")}
        >
          Download CSV
        </Button>
      )}

      <br />
      <div className="App">
        <div className="ag-theme-alpine" style={{ height: "500px" }}>
          <AgGridReact
            id="Inventory"
            columnDefs={columnDefs}
            rowData={rowData}
            defaultColDef={defaultColDef}
            // onGridReady={onGridReady}
            pagination={true}
            // paginationPageSize={5}
            // paginationAutoPageSize={true}
            alwaysShowHorizontalScroll={true}
            alwaysShowVerticalScroll={true}
          ></AgGridReact>
        </div>
      </div>
    </>
  );
};

export default reduxForm({
  form: "Agentsalesreport",
})(Agentsalesreport);
