import React from 'react'
import Titlebanner from "../../../globel_cmponents/title_banner";
import FormAddonBarCodeForm from "./attractionForms/FormAddonBarCodeForm";

const FormAddonBarCode = () => {
    return (  <>
    <Titlebanner title="Form Addon BarCode Upload"/>
    <FormAddonBarCodeForm/>
    </>);
}
 
export default FormAddonBarCode;