/** @format */

import AdminRoles from "../admin/admin-roles";
import AdminRoleProLinks from "../admin/admin_role_pgmlink";
import Adminrolecreate from "../admin/admin_role_create";

import Attractioncreation from "../attraction/attraction_creation";
import Attractioncreationform from "../attraction/attraction_creation_from";
import Newagencycreation from "../admin/admin_agency_new";
import AgencyCreation from "../admin/agency_creation";
import Ticketcreatenew from "../admin/admin_ticket_new";
import TicketType from "../admin/ticket_type";
import Parkgrouping from "../attraction/park_grouping";
import Parkgroupingnew from "../attraction/park_grouping_new";
import Customertype from "../admin/customer_type";
import CustomerTypenew from "../admin/customer_type_create";
import CreateVisa from "../visa/createVisa";
import Passengercreatenew from "../admin/passenger_type_new";
import Paymentdetails from "../payment/payment_details";
import Addpaymentcreation from "../payment/add_payment_creation";
import Markupcreation from "../payment/markup_discount";
import Markupdiscountcreate from "../payment/markup_discount_create";
import Parklistforbooking from "../booking/parklist_for_booking";
import Ticketbook from "../booking/ticket_book";
import Ticketbookingform from "../booking/tickrt_boking_form";
import Ticketreturn from "../booking/ticket_return";
import Dailysalesreport from "../reports/daily_sales_report";
import Agentsalesreport from "../reports/agent_sales_report";
import Cancellationreport from "../reports/cancellation_report";
import Stacksummeryrepo from "../reports/stack_summery_report";
import Inventortdetailreport from "../reports/inventory_detail_report";
import Statmentreport from "../reports/statment_report";

import AdminIndex from "../admin/admin_index";

import Onlinepayment from "../payment/online_payment";
import Addpaybyuser from "../payment/add_payby_user";
import Attractionlisttable from "../attraction/attraction_list_table";
import TicketCreation from "../attraction/ticket_creation";
import TourPackCreation from "../attraction/tour_pack_creation";
import LoginPage from "../../Unauth/login";
import successmessage from "../../../globel_cmponents/successMsg";
import Ticketlisttable from "../attraction/tkt_list_table";
import Tourpacklisttable from "../attraction/tour_pack_list_table";
import BannerAddOn from "../attraction/banner_add_on";
import FormAdddOn from "../attraction/form_add_on";
import Dashboard from "../../../globel_cmponents/dashboard";
import TicketEditForm from "../attraction/attractionForms/TicketEditForm";
import TktEditForm from "../attraction/tkt_edit_form";
import Agencylistmarkup from "../admin/agency_list_for_markup";
import Pdfgenerate from "../admin/pdf_generate";
import Combopackcreation from "../attraction/combo_creation";
import ConnectWithAPI from "../attraction/connect_with_api";
import TourPackEnquiry from "../attraction/tour_pack_Enquiry";
import VisaEnquiry from "../Enquiry/visa_enquiry";
import CommonEnquiry from "../Enquiry/common_enquiry";
import ApiTicketReport from "../reports/api_tkt_report";
import BannerImages from "../admin/banner_images";
import PassengerTypeDetails from "../admin/passenger_type_details";
import PassengerType from "../admin/forms/PassengerType";
import FormAddonBarCode from "../attraction/formAddon_Barcode";
import TourpackGrouping from "../attraction/tourpack_grouping";
import TourGroupForm from "../attraction/attractionForms/TourGroupForm";
import ComboListTable from "../attraction/comboList";
import VisaList from "../visa/visaList";
import VisaDetails from "../Enquiry/VisaDetails";
import PriceSetting from "../api/PriceSettings";
import MarkupDiscount from "../api/MarkupDiscount";
import ReturnTicketList from "../reports/return_tickets_list";
import ReturnTicketDetails from "../reports/return_tickets_detail";
import ExpirtTktDetails from "../../../globel_cmponents/Tables/ExpTktDetails";
import bookinglist from "../reports/bookinglist";
import MarkupTable from "../api/Tables/MarkupTable";
import MonthlySalesRep from "../../../globel_cmponents/Charts/MonthlySalesRep";
import MonthlySalesReport from "../reports/monthly_sales_report";
import AddonStockReport from "../reports/addon_stock_report";
import AddHolidays from "../attraction/AddHolidays";
import ApiAccessPermistion from "../agentapi/ApiAccessPermistion";
import TicketUpload from "../agentapi/TicketUpload";
import CreateTransport from "../transport/CreateTransport";
import TransportList from "../transport/TransportList";
import LinkTransportList from "../transport/LinkTransportList";
import LinkTransport from "../transport/LinkTransport";
import CreateHotel from "../hotel/CreateHotel";
import HotelList from "../hotel/HotelList";
import AttractionTransport from "../transport/AttractionTransport";
import ListAminities from "../hotel/ListAminities";
import CraeteAminities from "../hotel/CraeteAminities";
import LanguageList from "../websetting/languageList";
import CreateLanguage from "../websetting/createLanguage";
import ReviewList from "../reviewAndfaq/ReviewList";
import FaqList from "../reviewAndfaq/FaqList";
import CreateReview from "../reviewAndfaq/CreateReview";
import CreateFaq from "../reviewAndfaq/CreateFaq";
import BannerImageBtoC from "../websetting/BannerImageBtoC";
import GenerateTempTicket from "../attraction/GenerateTempTicket";
import AttractionTransferList from "../transport/AttractionTransferList";
import BookingWithTransfer from "../reports/BookingWithTransfer";
import TransportBookingList from "../transport/TransportBookingList";
import HotelEnquiryList from "../hotel/HotelEnquiryList";
import TicketAvailability from "../reports/ticket_availability";
import TicketCreationDublicate from "../attraction/attractionForms/TicketCreationDublicate";
import B2cbookinfList from "../reports/B2cbookinfList";
import CreateMarkup from "../TourVisionery/CreateMakup";
import MarkupsList from "../TourVisionery/MarkupsList";
import Burjkhalifamarkup from "../TourVisionery/Burjkhalifamarkup";
let urlId = sessionStorage.getItem("randomString");
const routes = [
  {
    path: "/index",
    component: AdminIndex,
  },
  {
    path: "/dashboard/dashboard/index",
    component: Dashboard,
  },
  {
    path: "/APISettings/APIMaster/attraction-connect-with_api",
    component: ConnectWithAPI,
  },
  {
    path: "/admin/:id/roles",
    component: AdminRoles,
  },
  {
    path: "/admin/roles-create",
    component: Adminrolecreate,
  },
  {
    path: "/admin/roles/roles-create-new/:id",
    component: Adminrolecreate,
  },

  {
    path: "/admin/roleProgramLink/role-programe-link",
    component: AdminRoleProLinks,
  },
  {
    path: "/admin/CustomerType/admin-customer-type",
    component: Customertype,
  },
  {
    path: "/admin/CustomerType/admin-customer-type-new",
    component: CustomerTypenew,
  },
  {
    path: "/admin/customer-list-for-markup/:id/:name/:status/:desc",
    component: Agencylistmarkup,
  },

  {
    path: "/admin/TicketType/admin-ticket-type-creation/:id",
    component: TicketType,
  },
  {
    path: "/admin/TicketType/admin-ticket-type-new/:id",
    component: Ticketcreatenew,
  },
  {
    path: "/admin/SubUserCreation/admin-passenger-type",
    component: PassengerTypeDetails,
  },
  {
    path: "/admin/SubUserCreation/admin-usertype-creation/:id",
    component: PassengerType,
  },
  {
    path: "/Attraction/AddOnBarcode/attraction-form-add-on-BarcodeUpload",
    component: FormAddonBarCode,
  },
  {
    path: "/websiteSettings/bannerImages/admin-banner-images",
    component: BannerImages,
  },
  {
    path: "/admin/passenger-create-new",
    component: Passengercreatenew,
  },

  {
    path: "/admin/AgencyCreation/admin-agency-creation",
    component: AgencyCreation,
  },
  {
    path: "/admin/AgencyCreation/admin-agency-create-new/:id",
    component: Newagencycreation,
  },
  {
    path: "/Payment/PaymentCreation/payment-payment-creation",
    component: Paymentdetails,
  },

  {
    path: "/payment/OnlinePayment/online-payment",
    component: Onlinepayment,
  },
  {
    path: "/APISettings/PriceSettings/apimaster-price-settings",
    component: PriceSetting,
  },
  {
    path: "/APISettings/APISettings/apimaster-markup-discount",
    component: MarkupDiscount,
  },
  {
    path: "/apimaster/markup-data",
    component: MarkupTable,
  },
  {
    path: "/Payment/PaymentCreation/payment-add-payment-creation",
    component: Addpaymentcreation,
  },
  {
    path: "/payment/OnlinePayment/online-payment-makepay",
    component: Addpaybyuser,
  },
  {
    path: "/Payment/markupDiscount/payment-markup-discount",
    component: Markupcreation,
  },
  {
    path: "/Payment/markupDiscount/payment-markup-discount-create/:id",
    component: Markupdiscountcreate,
  },

  {
    path: "/Attraction/AttractionCreation/attraction-attraction-creation/:id",
    component: Attractioncreationform,
  },
  {
    path: "/TourPack/TourPackCreation/attraction-tour-pack-creation/:id",
    component: TourPackCreation,
  },
  {
    path: "/TourPack/TourPackList/attraction-tour-pack-list",
    component: Tourpacklisttable,
  },

  {
    path: "/EnquiryList/TourEnquiry/attraction-tour-pack-enquiry",
    component: TourPackEnquiry,
  },

  {
    path: "/EnquiryList/VisaEnquiry/enquiry-visa-enquiry",
    component: VisaEnquiry,
  },

  {
    path: "/EnquiryList/CommonEnquiry/enquiry-common-enquiry",
    component: CommonEnquiry,
  },
  {
    path: "/Attraction/AttractionCreation/attraction-attraction-creation-new",
    component: Attractioncreationform,
  },
  {
    path: "/Attraction/AttractionList/attraction-attraction-list",

    component: Attractionlisttable,
  },
  {
    path: "/Attraction/TicketCreation/attraction-ticket-creation",

    component: TicketCreation,
  },
  {
    path: "/Attraction/TicketGeneration/attraction-ticket-generate-temp",
    component: GenerateTempTicket,
  },
  {
    path: "/Attraction/ParkGrouping/attraction-park-grouping",
    component: Parkgrouping,
  },
  {
    path: "/Attraction/ParkGrouping/attraction-park-grouping-new/:id",
    component: Parkgroupingnew,
  },
  {
    path: "/Attraction/BannerAddOn/attraction-banner-add-on",
    component: BannerAddOn,
  },
  {
    path: "/Attraction/FormAddOn/attraction-form-add-on",
    component: FormAdddOn,
  },
  {
    path: "/Attraction/TicketList/attraction-ticket-list",
    component: Ticketlisttable,
  },
  {
    path: "/TourPack/TourPackGroup/attraction-tour-pack-group",
    component: TourpackGrouping,
  },
  {
    path: "/Attraction/ComboPacklist/attraction-combo-pack-list",
    component: ComboListTable,
  },
  {
    path: "/TourPack/TourPackGroup/attraction-tour-packgroup-form",
    component: TourGroupForm,
  },
  {
    path: "/Booking/Booking/ticket-booking",
    component: Parklistforbooking,
  },

  {
    path: "/Booking/Booking/ticket-booking-detail-view/:id",
    component: Ticketbook,
  },
  {
    path: "/Booking/Booking/ticket-booking-detail-and-form",
    component: Ticketbookingform,
  },
  {
    path: "/Booking/ReturnTickets/ticket-return",
    component: Ticketreturn,
  },
  {
    path: "/Reports/DailySales/report-daily-sales-report",
    component: Dailysalesreport,
  },
  {
    path: "/Reports/AgentSalesReport/report-agent-sales-report",
    component: Agentsalesreport,
  },
  {
    path: "/report/cancellation-report",
    component: Cancellationreport,
  },
  {
    path: "/Reports/StockSummery/report-stack-summery-report",
    component: Stacksummeryrepo,
  },
  {
    path: "/Reports/Inventory/report-inventory-detail-report",
    component: Inventortdetailreport,
  },
  {
    path: "/report/statment-report",
    component: Statmentreport,
  },
  {
    path: "/success",
    component: successmessage,
  },
  {
    path: "/attraction-tkt-edit-page/:id",
    component: TktEditForm,
  },
  {
    path: "/admin/GeneratePDF/admin-pdf-generate",
    component: Pdfgenerate,
  },
  {
    path: "/Attraction/ComboPackCreation/attraction-combo-pack-creation/:id",
    component: Combopackcreation,
  },
  {
    path: "/Reports/APITicket/report-api-ticket-booking-report",
    component: ApiTicketReport,
  },
  {
    path: "/Visa/VisaCreation/visa-create-visa/:id",
    component: CreateVisa,
  },
  {
    path: "/Visa/VisaList/visa-visa-list",
    component: VisaList,
  },
  {
    path: "/visa/visa-details/:id",
    component: VisaDetails,
  },
  {
    path: "/Reports/Reports/report-return-ticket-list",
    component: ReturnTicketList,
  },
  {
    path: "/report/return-ticket-details/:id",
    component: ReturnTicketDetails,
  },

  {
    path: "/Reports/TicketAvailability/report-ticket-availability-list",
    component: TicketAvailability,
  },
  {
    path: "/dashboard-soon-expiry-ticket-details/:id",
    component: ExpirtTktDetails,
  },
  {
    path: "/Reports/BookingList/report-all-booking-list",
    component: bookinglist,
  },
  {
    path: "/Reports/MonthlySales/report-monthly-sales-report",
    component: MonthlySalesReport,
  },
  {
    path: "/Reports/AddonStock/report-addon-stock-report",
    component: AddonStockReport,
  },
  {
    path: "/Attraction/SetHolidays/attraction-set-hollydays",
    component: AddHolidays,
  },
  {
    path: "/AgentAPISettings/CreateAccesspermission/api-access-permistion",
    component: ApiAccessPermistion,
  },
  {
    path: "/AgentAPISettings/UploadTestDummyTicket/api-upload-test-tickets",
    component: TicketUpload,
  },
  {
    path: "/Trasnport/ListTransport/transport-list-transport",
    component: TransportList,
  },
  {
    path: "/Trasnport/TransportBookedList/transport-transport-booking-list",
    component: TransportBookingList,
  },
  {
    path: "/Trasnport/ListTransport/transport-create-transport/:id",
    component: CreateTransport,
  },
  {
    path: "/transport/link-transport-list",
    component: LinkTransportList,
  },
  {
    path: "/transport/link-transport",
    component: LinkTransport,
  },
  {
    path: "/Hotel/CreateHotel/hotel-create-hotel/:id",
    component: CreateHotel,
  },
  {
    path: "/Hotel/ListHotel/hotel-list-hotel",
    component: HotelList,
  },
  {
    path: "/Trasnport/AttractionTransport/transport-attraction-transport",
    component: AttractionTransferList,
  },
  {
    path: "/Trasnport/AttractionTransport/transport-create-attraction-transport",
    component: AttractionTransport,
  },
  // {
  //   path: "/Trasnport/ListTransport/transport-create-transport",
  //   component: AttractionTransport,
  // },

  {
    path: "/Hotel/HotelAminities/hotel-list-amities",
    component: ListAminities,
  },
  {
    path: "/hotel-create-amities",
    component: CraeteAminities,
  },

  {
    path: "/websiteSettings/languageSettings/admin-banner-images",
    component: LanguageList,
  },
  {
    path: "/websiteSettings/languageSettings/admin-create-language/:id",
    component: CreateLanguage,
  },
  {
    path: "/ReviewAndFaq/Review/randf-reviewlist",
    component: ReviewList,
  },
  {
    path: "/ReviewAndFaq/FAQ/randf-faqslist",
    component: FaqList,
  },
  {
    path: "/ReviewAndFaq/randf-createreview/:id",
    component: CreateReview,
  },
  {
    path: "/ReviewAndFaq/FAQ/randf-createfaq/:id",
    component: CreateFaq,
  },
  {
    path: "/websiteSettings/bannerImages/admin-banner-imagesbtoc",
    component: BannerImageBtoC,
  },
  {
    path: "/Reports/AttractionWithTransfer/report-attractiontkt-withtransfer",
    component: BookingWithTransfer,
  },
  {
    path: "/Hotel/HotelEnquiryList/hotel-create-hotel",
    component: HotelEnquiryList,
  },
  {
    path: "/Attraction/TicketCreation/attraction-ticket-creation-withdublicate",
    component: TicketCreationDublicate,
  },
  {
    path: "/Reports/B2CSales/report-b2c-sales-report",
    component: B2cbookinfList,
  },
  {
    path: "/payment/tourvisionary-createmarkup/:id",
    component: CreateMarkup,
  },
  {
    path: "/payment/tourvisionary-listmarkups",
    component: MarkupsList,
  },
  {
    path: "/TourVisionaryMarkups/CreateAPIMarkup/payment-tourvisionary-createapimarkup",
    component: Burjkhalifamarkup,
  },

  //TicketCreationDublicate
];
export default routes;
///Hotel/HotelAminities/hotel-list-amities  hotel-create-amities
