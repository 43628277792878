/** @format */

import React from "react";
const TourEnq = () => {
  return (
    <>
     
        <div className='small-box bg-info'>
          <div className='inner'>
            <h3>0</h3>
            <p>Tour Enquiry</p>
          </div>
          <div className='icon'>
            {/* <i className='ion ion-bag' /> */}
          </div>
          {/* <a href='#' className='small-box-footer'>
            More info <i className='fas fa-arrow-circle-right' />
          </a> */}
        </div>
    
    </>
  );
};
export default TourEnq;
