import React, { useEffect, useState } from "react";
import Titlebanner from "../../../globel_cmponents/title_banner";
import { Card, CardBody, Row, Col, Button, Label } from "reactstrap";
import { reduxForm, Field } from "redux-form";
import RenderField from "../../formcomponent/formfields/RenderField";
import DropZoneSingleRender from "../../formcomponent/formfields/dropzonSingle/DropZoneSingleRender";
import axios from "axios";
import requests from "../../../utils/Requests";
import { useParams, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import EditorField from "../../formcomponent/editorfield/EditorField";
import EditorFieldComponent from "../../formcomponent/editorfield/EditorFieldComponent";
const validate = (values) => {
  const errors = {};
  if (!values.tktAdultOrChild) {
    errors.tktAdultOrChild = "Required";
  }
  return errors;
};

const CreateTransport = (props) => {
  const { handleSubmit, pristine, reset, submitting } = props;
  const { id } = useParams();
  const history = useHistory();
  const [isLoading, setisLoading] = useState(true);
  useEffect(() => {
    if (id != "null") {
      getTransportList();
    } else {
      setisLoading(false);
    }
  }, [id]);
  const [existImage, setexistImage] = useState("");
  const getTransportList = async () => {
    await axios
      .post(requests.getTransferList, { transferId: 1 })
      .then((res) => {
        console.log(res.data);
        const resData = res.data;
        const filterObject = resData.find((item) => item.transferId == id);
        console.log(`${JSON.stringify(filterObject, null, 2)}`);
        initializeData(filterObject);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const initializeData = (data) => {
    setexistImage(data.transferImage);
    props.initialize({
      transferId: data.transferId,
      transportName: data.transportName,
      transferImage: data.transferImage,
      nofPassengers: data.nofPassengers,
      cost: data.cost,
      typeOfVehicle: data.typeOfVehicle,
      vehicle: data.vehicle,
      description: data.description,
      transferVehicleList: data.transferVehicleList,
      termsAndConditions: data.termsAndConditions,
    });
    setvechileTempArr(data.transferVehicleList);
    setisLoading(false);
  };

  const [vechileTempArr, setvechileTempArr] = useState([]);
  const [vechileDeleteList, setvechileDeleteList] = useState([]);
  const [newVehicle, setNewVehicle] = useState(""); // To store the new vehicle temporarily

  const addVechieTempArr = () => {
    // Only add a new vehicle if it's not empty
    if (newVehicle.trim() !== "") {
      setvechileTempArr([...vechileTempArr, { vehicleName: newVehicle }]);
      setNewVehicle(""); // Clear the input field
      props.change("addVechile", "");
    }
  };

  const removeVehicle = (index) => {
    const removedVehicle = vechileTempArr[index];
    setvechileDeleteList([...vechileDeleteList, removedVehicle]);
    const updatedList = [...vechileTempArr];
    updatedList.splice(index, 1);
    setvechileTempArr(updatedList);
  };
  const postToApi = async (values) => {
    console.log(values);

    const postObject = {
      ...values,
      transferVehicleList: vechileTempArr,
      transferVehicleDeleteList: vechileDeleteList,
    };

    console.log("post object", postObject);
    await axios
      .post(requests.createTransfer, postObject)
      .then((res) => {
        console.log(res.data);
        Swal.fire("Success", "Transfer Added", "success");
        history.goBack();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <Titlebanner title="Create Transport" />

      {isLoading === true ? (
        <>
          {" "}
          <h3>Loading Please Wait</h3>
        </>
      ) : (
        <Card>
          <CardBody>
            <Row>
              <Col>
                <Field
                  name="transportName"
                  type="text"
                  label="Name"
                  component={RenderField.RenderTextField}
                />
              </Col>
              <Col>
                <Field
                  name="typeOfVehicle"
                  type="text"
                  label="Type Of Vechile"
                  component={RenderField.RenderTextField}
                />
              </Col>
              <Col>
                <Field
                  name="vehicle"
                  type="text"
                  label="Vehicle"
                  component={RenderField.RenderTextField}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Field
                  name="cost"
                  type="text"
                  label="Cost"
                  component={RenderField.RenderTextField}
                />
              </Col>
              <Col>
                <Field
                  name="nofPassengers"
                  type="text"
                  label="No of Passengers"
                  component={RenderField.RenderTextField}
                />
              </Col>
              <Col></Col>
            </Row>
            <Row>
              <Col>
                <Field
                  name="description"
                  label="Description"
                  type="textarea"
                  rows="5"
                  component={RenderField.RenderTextareaField}
                />
              </Col>
              <Col>
                {existImage != null ? (
                  <img
                    src={
                      "https://parmartours.com:8443/filestorage/parmartour/images/" +
                      existImage
                    }
                    width="100px"
                    height="100px"
                    alt="placeholder grey 100px"
                  />
                ) : null}
                <br />

                <Label>Vechile Image</Label>

                <Field
                  // key="field"
                  name="transferImage"
                  component={DropZoneSingleRender}
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <Field
                  label="Add Vehicle"
                  name="addVechile"
                  type="text"
                  component={RenderField.RenderTextField}
                  onChange={(e) => setNewVehicle(e.target.value)} // Update the new vehicle state
                  value={newVehicle} // Bind the input value to the state
                />
              </Col>
              <Col>
                <div
                  style={{
                    marginTop: "33px",
                  }}
                >
                  <Button color="primary" onClick={() => addVechieTempArr()}>
                    Add
                  </Button>
                </div>
              </Col>
            </Row>
            {vechileTempArr &&
              vechileTempArr.map((item, index) => (
                <p key={index}>
                  {item.vehicleName}{" "}
                  <Button color="danger" onClick={() => removeVehicle(index)}>
                    Remove
                  </Button>
                </p>
              ))}
            <br />

            <EditorField
              key="field"
              name="termsAndConditions"
              id="inputEditorText"
              disabled={false}
              component={EditorFieldComponent}
              placeholder="Type here"
            />
            <div className="float-right">
              <Button
                color="primary"
                onClick={handleSubmit((values) =>
                  postToApi({
                    ...values,
                    pill: 1,
                  })
                )}
              >
                Save
              </Button>
            </div>
          </CardBody>
        </Card>
      )}
    </div>
  );
};

export default reduxForm({
  form: "CreateTransport",
  validate,
})(CreateTransport);

//8686113537 sudhakar crakers
