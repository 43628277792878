import encryptStorage from "../../../utils/EncryptStorage";
const loginDetails = encryptStorage.getItem("DFuck");
let userPermissions = null;
if (loginDetails) {
  userPermissions = JSON.parse(loginDetails);
}

const SideMenu = [
  {
    name: "Dashboard",
    fixMenu: false,
    // path: "/dashboard/dashboard/index",
    SpanIcon: "nav-icon fas fa-th",

    authMenu: "dashboard",

    SideMenu: [
      {
        name: "DashBoard",
        path: "/dashboard/dashboard/index",
        authMenu: "dashboard",
      },
    ],
  },

  {
    name: "Website Settings",
    SpanIcon: "fa fa-cog",
    fixMenu: false,
    authMenu: "websiteSettings",
    SideMenu: [
      {
        name: "Banner Images",
        path: "/websiteSettings/bannerImages/admin-banner-images",
        authMenu: "bannerImages",
      },
      {
        name: "Banner Image(BtoC)  ",
        path: "/websiteSettings/bannerImages/admin-banner-imagesbtoc",
        authMenu: "",
      },
      {
        name: "Language  Settings",
        path: "/websiteSettings/languageSettings/admin-banner-images",
        authMenu: "languageSettings",
      },
    ],
  },
  {
    name: "Admin",
    SpanIcon: "fa fa-user-secret",
    fixMenu: false,
    authMenu: "admin",
    SideMenu: [
      {
        name: "Roles",
        path: "/admin/roles/roles",
        authMenu: "roles",
      },
      {
        name: "Role Program Link",
        path: "/admin/roleProgramLink/role-programe-link",
        authMenu: "roleProgramLink",
      },
      {
        name: "Customer Type",
        path: "/admin/CustomerType/admin-customer-type",
        authMenu: "CustomerType",
      },
      {
        name: "Ticket Type",
        path: "/admin/TicketType/admin-ticket-type-creation/null",
        authMenu: "TicketType",
      },

      {
        name: "Sub-User Creation",
        path: "/admin/SubUserCreation/admin-passenger-type",
        authMenu: "SubUserCreation",
      },
      {
        name: "Agency Creation",
        path: "/admin/AgencyCreation/admin-agency-creation",
        authMenu: "AgencyCreation",
      },
      {
        name: "Generate PDF",
        path: "/admin/GeneratePDF/admin-pdf-generate",
        authMenu: "GeneratePDF",
      },
    ],
  },

  {
    name: "Payment",
    SpanIcon: "fa fa-credit-card",
    fixMenu: false,
    authMenu: "Payment",
    SideMenu: [
      {
        name: "Payment Creation",
        path: "/Payment/PaymentCreation/payment-payment-creation",
        authMenu: "paymentCreation",
      },
      {
        name: "Online Payment",
        path: "/payment/OnlinePayment/online-payment",
        authMenu: "OnlinePayment",
      },
      {
        name: "Markup Discount",
        path: "/Payment/markupDiscount/payment-markup-discount",
        authMenu: "markupDiscount",
      },
    ],
  },

  {
    name: "API Settings",

    SpanIcon: "fa fa-credit-card",
    fixMenu: false,
    authMenu: "APISettings",
    SideMenu: [
      {
        name: "API Master",
        path: "/APISettings/APIMaster/attraction-connect-with_api",
        authMenu: "APIMaster",
      },
      {
        name: "Price Settings",
        path: "/APISettings/PriceSettings/apimaster-price-settings",
        authMenu: "PriceSettings",
      },
      {
        name: "MarkUp Discount",
        path: "/APISettings/APISettings/apimaster-markup-discount",
        authMenu: "APISettings",
      },
    ],
  },

  {
    name: "Agent API Settings",

    SpanIcon: "fa fa-credit-card",
    fixMenu: false,
    authMenu: "AgentAPISettings",
    SideMenu: [
      {
        name: "Create Access permission",
        path: "/AgentAPISettings/CreateAccesspermission/api-access-permistion",
        authMenu: "CreateAccesspermission",
      },
      {
        name: "Upload Test(Dummy) Ticket",
        path: "/AgentAPISettings/UploadTestDummyTicket/api-upload-test-tickets",
        authMenu: "UploadTestDummyTicket",
      },
    ],
  },
  {
    name: "Attraction",
    fixMenu: false,
    SpanIcon: "fa fa-globe",
    authMenu: "Attraction",
    SideMenu: [
      {
        name: "Attraction Creation",
        path: `/Attraction/AttractionCreation/attraction-attraction-creation/null`,
        authMenu: "AttractionCreation",
      },

      {
        name: "Attraction List",
        path: "/Attraction/AttractionList/attraction-attraction-list",
        authMenu: "AttractionList",
      },
      {
        name: "Set Holidays",
        path: "/Attraction/SetHolidays/attraction-set-hollydays",
        authMenu: "SetHolidays",
      },

      {
        name: "Ticket Creation",
        path: "/Attraction/TicketCreation/attraction-ticket-creation",
        authMenu: "TicketCreation",
      },

      {
        name: "Ticket Generation",
        path: "/Attraction/TicketGeneration/attraction-ticket-generate-temp",
        authMenu: "TicketGeneration",
      },
      {
        name: "Ticket List",
        path: "/Attraction/TicketList/attraction-ticket-list",
        authMenu: "TicketList",
      },
      {
        name: "Combo Pack Creation",
        path: "/Attraction/ComboPackCreation/attraction-combo-pack-creation/null",
        authMenu: "ComboPackCreation",
      },
      {
        name: "Combo Pack list",
        path: "/Attraction/ComboPacklist/attraction-combo-pack-list",
        authMenu: "ComboPacklist",
      },
      {
        name: "Park Grouping",
        path: "/Attraction/ParkGrouping/attraction-park-grouping",
        authMenu: "ParkGrouping",
      },
      {
        name: "Banner Add-On",
        path: "/Attraction/BannerAddOn/attraction-banner-add-on",
        authMenu: "BannerAddOn",
      },
      {
        name: "Form Add-On",
        path: "/Attraction/FormAddOn/attraction-form-add-on",
        authMenu: "FormAddOn",
      },
      {
        name: "Add-On Barcode",
        path: "/Attraction/AddOnBarcode/attraction-form-add-on-BarcodeUpload",
        authMenu: "AddOnBarcode",
      },
    ],
  },
  {
    name: "Tour Pack",
    fixMenu: false,
    authMenu: "TourPack",
    SpanIcon: "fa fa-globe",
    SideMenu: [
      {
        name: "Tour Pack Group",
        path: "/TourPack/TourPackGroup/attraction-tour-pack-group",
        authMenu: "TourPackGroup",
      },
      {
        name: "Tour Pack Creation",
        path: "/TourPack/TourPackCreation/attraction-tour-pack-creation/null",
        authMenu: "TourPackCreation",
      },
      {
        name: "Tour Pack List",
        path: "/TourPack/TourPackList/attraction-tour-pack-list",
        authMenu: "TourPackList",
      },
    ],
  },
  {
    name: "Hotel",
    authMenu: "Hotel",
    SpanIcon: "fa fa-building",
    fixMenu: false,
    SideMenu: [
      {
        name: "Hotel Aminities",
        path: "/Hotel/HotelAminities/hotel-list-amities",
        authMenu: "HotelAminities",
      },
      {
        name: "List Hotel",
        path: "/Hotel/ListHotel/hotel-list-hotel",
        authMenu: "ListHotel",
      },
      {
        name: "Create Hotel",
        path: "/Hotel/CreateHotel/hotel-create-hotel/null",
        authMenu: "CreateHotel",
      },
      {
        name: "Hotel Enquiry List",
        path: "/Hotel/HotelEnquiryList/hotel-create-hotel",
        authMenu: "HotelEnquiryList",
      },
    ],
  },

  {
    name: "Trasnport",
    authMenu: "Trasnport",
    SpanIcon: "fa fa-credit-card",
    fixMenu: false,
    SideMenu: [
      {
        name: "Attraction Transport",
        path: "/Trasnport/AttractionTransport/transport-attraction-transport",
        authMenu: "AttractionTransport",
      },
      {
        name: "List Transport",
        path: "/Trasnport/ListTransport/transport-list-transport",
        authMenu: "ListTransport",
      },
      {
        name: "Transport Booked List",
        path: "/Trasnport/TransportBookedList/transport-transport-booking-list",
        authMenu: "TransportBookedList",
      },
      // {
      //   name: "Create Transport",
      //   path: "/Trasnport/ListTransport/transport-create-transport",
      // },
      // {
      //   name: "Link To Attraction",
      //   path: "/transport/link-transport-list",
      // },
    ],
  },

  {
    name: "Visa",
    fixMenu: false,
    authMenu: "Visa",
    SpanIcon: "fa fa-globe",
    SideMenu: [
      {
        name: "Visa Creation",
        path: "/Visa/VisaCreation/visa-create-visa/null",
        authMenu: "VisaCreation",
      },
      {
        name: "Visa List",
        path: "/Visa/VisaList/visa-visa-list",
        authMenu: "VisaList",
      },
    ],
  },
  {
    name: "Booking",
    fixMenu: false,
    authMenu: "Booking",
    SpanIcon: "fa fa-book",
    SideMenu: [
      {
        name: "Booking",
        path: "/Booking/Booking/ticket-booking",
        authMenu: "Booking",
      },
      {
        name: "Cancellation",
        path: "/booking/Booking/Cancellation/ticket-cansolation",
        authMenu: "Cancellation",
      },
      {
        name: "Return Tickets",
        path: "/Booking/ReturnTickets/ticket-return",
        authMenu: "ReturnTickets",
      },
    ],
  },
  {
    name: "Review And Faq",
    // path: "/admin",
    SpanIcon: "fa fa-cog",
    fixMenu: false,
    authMenu: "ReviewAndFaq",
    SideMenu: [
      {
        name: "Review",
        path: "/ReviewAndFaq/Review/randf-reviewlist",
        authMenu: "Review",
      },
      {
        name: "FAQ",
        path: "/ReviewAndFaq/FAQ/randf-faqslist",
        authMenu: "FAQ",
      },
    ],
  },
  {
    name: "Reports",
    fixMenu: false,
    authMenu: "Reports",
    SpanIcon: "fa fa-list-alt",
    SideMenu: [
      {
        name: "Daily Sales ",
        path: "/Reports/DailySales/report-daily-sales-report",
        authMenu: "DailySales",
      },
      {
        name: "B2C Sales",
        path: "/Reports/B2CSales/report-b2c-sales-report",
        authMenu: "B2CSales",
      },
      {
        name: "Monthly Sales ",
        path: "/Reports/MonthlySales/report-monthly-sales-report",
        authMenu: "MonthlySales",
      },
      {
        name: "API Ticket ",
        path: "/Reports/APITicket/report-api-ticket-booking-report",
        authMenu: "APITicket",
      },
      {
        name: "Booking List",
        path: "/Reports/BookingList/report-all-booking-list",
        authMenu: "BookingList",
      },
      {
        name: "Attraction With Transfer ",
        path: "/Reports/AttractionWithTransfer/report-attractiontkt-withtransfer",
        authMenu: "AttractionWithTransfer",
      },
      {
        name: "Return Tickets",
        path: "/Reports/Reports/report-return-ticket-list",
        authMenu: "",
      },

      {
        name: "Addon Stock",
        path: "/Reports/AddonStock/report-addon-stock-report",
        authMenu: "AddonStock",
      },
      {
        name: "Agent Sales Report",
        path: "/Reports/AgentSalesReport/report-agent-sales-report",
        authMenu: "AgentSalesReport",
      },
      // {
      //   name: "Cancellation Report",
      //   path: "/report/cancellation-report",
      //   reportsCancellationReport: true,
      // },
      {
        name: "Stock Summery ",
        path: "/Reports/StockSummery/report-stack-summery-report",
        authMenu: "StockSummery",
      },
      {
        name: "Inventory ",
        path: "/Reports/Inventory/report-inventory-detail-report",
        authMenu: "Inventory",
      },
      {
        name: "Ticket Availability ",
        path: "/Reports/TicketAvailability/report-ticket-availability-list",
        authMenu: "TicketAvailability",
      },
      // {
      //   name: "Statment Report",
      //   path: "/report/statment-report",
      //   reportsStatementReport: true,
      // },
    ],
  },

  {
    name: "Enquiry List",
    fixMenu: false,
    authMenu: "EnquiryList",
    SpanIcon: "fa fa-list-alt",
    SideMenu: [
      {
        name: "Tour Enquiry",
        path: "/EnquiryList/TourEnquiry/attraction-tour-pack-enquiry",
        authMenu: "TourEnquiry",
      },
      {
        name: "Visa Enquiry",
        path: "/EnquiryList/VisaEnquiry/enquiry-visa-enquiry",
        authMenu: "VisaEnquiry",
      },
      {
        name: "Common Enquiry",
        path: "/EnquiryList/CommonEnquiry/enquiry-common-enquiry",
        authMenu: "CommonEnquiry",
      },
    ],
  },
  {
    name: "Tour Visionary Markups",
    // path: "/payment",
    authMenu: "TourVisionaryMarkups",
    SpanIcon: "fa fa-credit-card",
    fixMenu: false,
    SideMenu: [
      // {
      //   name: "Markup List",
      //   path: "/payment/tourvisionary-listmarkups",
      //   paymentCreation: true,
      // },

      // {
      //   name: "Create Markup ",
      //   path: "/payment/tourvisionary-createmarkup/null",
      //   paymentMarkupDiscount: true,
      // },
      {
        name: "Create API Markup ",
        path: "/TourVisionaryMarkups/CreateAPIMarkup/payment-tourvisionary-createapimarkup",
        authMenu: "CreateAPIMarkup",
      },
    ],
  },
];

function filterMenu(menuItems, userPermissions) {
  // Convert userPermissions keys to lowercase for case-insensitive comparison

  if (!userPermissions) return menuItems;

  const lowerCasePermissions = Object.keys(userPermissions).reduce(
    (acc, key) => {
      acc[key.toLowerCase()] = userPermissions[key];
      return acc;
    },
    {}
  );

  return menuItems
    .filter((menuItem) => {
      // Check authMenu in lowercase for case-insensitive comparison
      const authKey = menuItem.authMenu
        ? menuItem.authMenu.toLowerCase()
        : null;

      // Include the menu item if it either has no authMenu or the user has permission
      if (!authKey || lowerCasePermissions[authKey]) {
        // Recursively filter SideMenu if it exists
        if (menuItem.SideMenu && menuItem.SideMenu.length > 0) {
          menuItem.SideMenu = filterMenu(
            menuItem.SideMenu,
            lowerCasePermissions
          );
        }
        return true;
      }
      return false;
    })
    .filter((menuItem) => {
      // If a menu item has SideMenu, ensure it's not empty after filtering
      return !menuItem.SideMenu || menuItem.SideMenu.length > 0;
    });
}

// Example usage
const filteredMenu = filterMenu(SideMenu, userPermissions);

console.log(filteredMenu);

export default filteredMenu;
