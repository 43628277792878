/** @format */

import React, { useState, useEffect } from "react";
import { reduxForm, Form, Field } from "redux-form";
import axios from "axios";
import ReactApexChart from "react-apexcharts";
import { Button, Col, Row } from "reactstrap";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import RenderField from "../../pages/formcomponent/formfields/RenderField";
import requests from "../../utils/Requests";

const REPORT_TYPES = [
  { value: 1, label: "Daily" },
  { value: 2, label: "Weekly" },
  { value: 3, label: "Monthly" },
  { value: 4, label: "By Date" },
];

const FILE_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const FILE_EXTENSION = ".xlsx";

const INITIAL_FORM_VALUES = {
  period: 1,
  attractionId: 0,
  agentId: 0,
  platformId: 1,
};

const DailySalesRep = (props) => {
  const { handleSubmit, initialize } = props;

  // State Hooks
  const [allTicketList, setAllTicketList] = useState([]);
  const [attractionList, setAttractionList] = useState([]);
  const [agencyList, setAgencyList] = useState([]);
  const [bookingListData, setBookingListData] = useState([]);
  const [chartData, setChartData] = useState({ series: [], options: {} });
  const [isButtonShow, setIsButtonShow] = useState(false);
  const [reportRange, setReportRange] = useState("");

  // Initialize form and fetch data
  useEffect(() => {
    initialize(INITIAL_FORM_VALUES);
    const tempData = { ...INITIAL_FORM_VALUES, secretKey: requests.apiKey };
    fetchInitialData(tempData);
  }, [initialize]);

  const fetchInitialData = async (tempData) => {
    await Promise.all([getTicketTypes(), getAgencies(), getAttractions()]);
    getDataForChart(tempData);
  };

  // API Calls
  const getTicketTypes = async () => {
    try {
      const { data } = await axios.post(requests.gettickettypelist, {
        userRolesId: 1,
        platformId: 1,
      });
      setAllTicketList(data);
    } catch (error) {
      console.error(error);
    }
  };

  const getAgencies = async () => {
    try {
      const { data } = await axios.post(requests.getagencylist, {
        platformId: 1,
        attractionsId: 1,
        secretKey: requests.apiKey,
      });
      const agencies = [
        { label: "All", value: 0 },
        ...data.map(({ agencyName, agencyId }) => ({
          label: agencyName,
          value: agencyId,
        })),
      ];
      setAgencyList(agencies);
    } catch (error) {
      console.error(error);
    }
  };

  const getAttractions = async () => {
    try {
      const { data } = await axios.post(requests.getAttractionListForUpdate, {
        attractionId: 1,
      });
      const attractions = [
        { label: "All", value: 0 },
        ...data.map(({ attName, attractionsId }) => ({
          label: attName,
          value: attractionsId,
        })),
      ];
      setAttractionList(attractions);
    } catch (error) {
      console.error(error);
    }
  };

  const getDataForChart = async (values) => {
    try {
      const postData = { ...values, secretKey: requests.apiKey };
      const { data } = await axios.post(
        requests.getDashboardSalesReport,
        postData
      );
      initializeChartData(data, values.period);
      getListForExport(postData);
    } catch (error) {
      console.error(error);
    }
  };

  const getListForExport = async (postData) => {
    try {
      setIsButtonShow(false);
      const { data } = await axios.post(
        requests.getDashboardSalesList,
        postData
      );
      setBookingListData(data);
      setIsButtonShow(true);
    } catch (error) {
      console.error(error);
    }
  };

  // Chart Helpers
  const initializeChartData = (salesData, period) => {
    const b2bSales = salesData.salesCountList.map(
      ({ b2bSalesCount }) => b2bSalesCount
    );
    const b2cSales = salesData.salesCountList.map(
      ({ b2cSalesCount }) => b2cSalesCount
    );
    const periodTime = salesData.salesCountList.map(({ periodName }) =>
      periodName.slice(0, 6)
    );

    setChartData({
      series: [
        { name: "B2B Sales", data: b2bSales },
        { name: "B2C Sales", data: b2cSales },
      ],
      options: {
        chart: { type: "bar", height: 350 },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "50%",
            endingShape: "rounded",
          },
        },
        dataLabels: { enabled: false },
        stroke: { show: true, width: 2, colors: ["transparent"] },
        xaxis: {
          categories: periodTime,
          title: { text: getPeriodLabel(period) },
        },
        yaxis: { labels: { formatter: (value) => value.toString() } },
        colors: getChartColors(period),
      },
    });
  };

  const getPeriodLabel = (period) => {
    switch (period) {
      case 1:
        return "Daily";
      case 2:
        return "Weekly";
      default:
        return "Monthly";
    }
  };

  const getChartColors = (period) => {
    switch (period) {
      case 1:
        return ["#B3000C", "#0D5901"];
      case 2:
        return ["#000080", "#FFFF00"];
      default:
        return ["#800080", "#008080"];
    }
  };

  // Export to Excel
  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: FILE_TYPE });
    FileSaver.saveAs(data, fileName + FILE_EXTENSION);
  };

  const exportExcelData = () => {
    const processedData = bookingListData.map(
      ({ bookDate, bookTravellDate, ...rest }) => ({
        bookDate: new Date(bookDate).toLocaleDateString(),
        bookTravelDate: new Date(bookTravellDate).toLocaleDateString(),
        ...rest,
      })
    );
    exportToCSV(processedData, "Dashboard_Report");
  };

  // Form Submission
  const onSubmit = (values) => {
    if (values.pill === "getReport") {
      getDataForChart(values);
    } else {
      initialize(INITIAL_FORM_VALUES);
      getDataForChart({ ...INITIAL_FORM_VALUES, secretKey: requests.apiKey });
    }
  };

  return (
    <div className="card card-info">
      <div className="card-header">
        <h3 className="card-title">Sales Report</h3>
      </div>
      <div className="card-body">
        <Form>
          <Row>
            <Col sm={2}>
              <Field
                name="period"
                label="Select Period"
                component={RenderField.RenderSelectField}
                options={REPORT_TYPES}
                onChange={(e) => setReportRange(e)}
              />
            </Col>
            <Col sm={3}>
              <Field
                name="attractionId"
                label="Select Attraction"
                component={RenderField.RenderSelectField}
                options={attractionList}
              />
            </Col>
            <Col sm={2}>
              <Field
                name="agentId"
                label="Select Agent"
                component={RenderField.RenderSelectField}
                options={agencyList}
              />
            </Col>
            {reportRange === 4 && (
              <>
                <Col sm={3}>
                  <Field
                    name="startDate"
                    label="From Date"
                    type="date"
                    component={RenderField.RenderTextField}
                  />
                </Col>
                <Col sm={2}>
                  <Field
                    name="endDate"
                    label="To Date"
                    type="date"
                    component={RenderField.RenderTextField}
                  />
                </Col>
              </>
            )}
          </Row>

          <Row>
            <Col sm={2}>
              <br />
              <Button
                color="primary"
                onClick={handleSubmit((values) =>
                  onSubmit({ ...values, pill: "getReport" })
                )}
              >
                Get Report
              </Button>
            </Col>
            {isButtonShow && (
              <Col sm={2}>
                <br />
                <Button color="success" onClick={exportExcelData}>
                  Download Excel
                </Button>
              </Col>
            )}
          </Row>
        </Form>

        <div className="row">
          <ReactApexChart
            options={chartData.options}
            series={chartData.series}
            type="bar"
            height={350}
          />
        </div>
      </div>
    </div>
  );
};

export default reduxForm({ form: "SalesReportForm" })(DailySalesRep);
