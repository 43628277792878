import React, { useEffect, useState } from "react";
import Titlebanner from "../../../globel_cmponents/title_banner";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import requests from "../../../utils/Requests";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import Swal from "sweetalert2";
import { Button } from "reactstrap";

const TransportList = () => {
  const history = useHistory();
  useEffect(() => {
    getTransportList();
  }, []);
  const [rowData, setrowData] = useState([]);
  const getTransportList = () => {
    axios
      .post(requests.getTransferList, { transferId: 1 })
      .then((res) => {
        console.log(res.data);
        setrowData(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const columnDefs = [
    { headerName: "Name", field: "transportName" },
    { headerName: "Vehicle Type", field: "typeOfVehicle" },
    { headerName: "Vehicle", field: "vehicle" },
    { headerName: "Passengers", field: "nofPassengers" },
    { headerName: "Cost", field: "cost" },
    {
      headerName: "Delete",
      filter: false,
      field: "transferId",
      cellRendererFramework: (params) => (
        <div>
          <Button color="danger" onClick={() => actionButton(params)}>
            Delete
          </Button>
        </div>
      ),
    },
    {
      headerName: "Edit",
      filter: false,
      field: "transferId",
      cellRendererFramework: (params) => (
        <div>
          <Button color="primary" onClick={() => actionButtonEdit(params)}>
            Edit
          </Button>
        </div>
      ),
    },
  ];

  const actionButtonEdit = (params) => {
    history.push(
      `/Trasnport/ListTransport/transport-create-transport/${params.data.transferId}`
    );
  };

  const actionButton = async (params) => {
    await axios
      .post(requests.deleteAttractionTransfer, {
        transferId: params.data.transferId,
      })
      .then((res) => {
        getTransportList(params.data.transferId);
        console.log(res.data);
        Swal.fire("Deleted", "Attraction Transfer Deleted", "success");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const defaultColDef = {
    sortable: true,
    editable: true,
    flex: 1,
    filter: true,
    floatingFilter: true,
  };
  return (
    <div>
      <Titlebanner title="Transport List" />
      <div className="float-right">
        <Link
          to="/Trasnport/ListTransport/transport-create-transport/null"
          class="btn btn-primary"
          role="button"
        >
          Create New
        </Link>
      </div>
      <br />
      <br />
      <div className="App">
        <div className="ag-theme-alpine" style={{ height: "500px" }}>
          <AgGridReact
            columnDefs={columnDefs}
            rowData={rowData}
            defaultColDef={defaultColDef}
            //onGridReady={onGridReady}
            pagination={true}
            // paginationPageSize={5}
            paginationAutoPageSize={true}
          ></AgGridReact>
        </div>
      </div>
    </div>
  );
};

export default TransportList;
